import styled from 'styled-components';
import React from 'react';
import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TITLE_FONT } from '../../constants';
import { Col, Container, Row } from 'reactstrap';
import { SeeMore } from '../shared';
import { ClutchReview } from './clutch-review';

import goodFirms from '../../../static/images/awards/goodfirms.svg';
import disrupt2015 from '../../../static/images/awards/disrupt2015.svg';
import disrupt2016 from '../../../static/images/awards/disrupt2016.svg';
import collage from '../../../static/images/awards/collage.svg';
import clutch from '../../../static/images/awards/clutch.png';

const WraperContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background: ${PRIMARY_TEXT_COLOR};

    .awards-width {
        width: auto;
    }
`;

const WeAreProud = styled.div`
    font-size: 22px;
    letter-spacing: -0.2px;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_TITLE_COLOR};
    font-weight: lighter;
    margin-top: 24px;
    margin-bottom: 20px;

    @media (max-height: 700px) {
        font-size: 18px;
    }

    @media (max-width: 767px) {
        display: none;
    }

    @media screen and (orientation: landscape) {
        @media (max-height: 440px) {
            display: none;
        }
    }
`;

const ImageContainer = styled.div`
    margin-right: 3rem;

    img {
        max-width: 100%;
    }
`;

const CollageContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 40px;

    @media (max-width: 556px) {
        margin-bottom: 0;
        margin-top: 40px;
    }

    @media screen and (orientation: landscape) {
        display: none;
    }
`;

const AwardsTitle = styled.h1`
    font-size: 44px;
    font-weight: bold;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_TITLE_COLOR};

    @media (max-height: 700px) {
        font-size: 32px;
    }

    @media screen and (orientation: landscape) {
        @media (max-height: 700px) {
            margin-top: 40px;
        }
    }

    @media (max-width: 767px) {
        font-size: 18px;
        text-align: left;
        margin-top: 60px;
        @media screen and (orientation: portrait) {
            text-align: center;
            margin-top: 80px;
        }
    }
`;

/* const HeightConservingDiv = styled.div`
    display: none;
    @media (min-height: 619px) {
        display: block;
    }
`; */

const HideRowOnHeight = styled(Row)`
    @media (max-height: 700px) {
        display: none !important;
    }
`;

const ShowImageOnHeight = styled(ImageContainer)`
    display: none;

    @media (max-height: 700px) {
        display: block;
    }
`;

const LandscapeContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media screen and (orientation: portrait) {
        display: none;
    }
`;

export const Awards = () => {
    return (
        <WraperContainer>
            <Container className="d-none d-md-flex flex-column">
                <Row>
                    <Col>
                        <AwardsTitle>Awards & Recognitions.</AwardsTitle>
                        <WeAreProud>We are proud of our following recognitions.</WeAreProud>
                    </Col>
                </Row>
                <HideRowOnHeight className="mb-5">
                    <Col xs={6} md={12}>
                        <ClutchReview />
                    </Col>
                </HideRowOnHeight>
                <Row>
                    <Col md={12} className="d-flex flex-row">
                        <ShowImageOnHeight>
                            <img src={clutch} alt={'We are on clutch'} />
                        </ShowImageOnHeight>
                        <ImageContainer>
                            <img src={goodFirms} alt={'Awarded good firms'} />
                        </ImageContainer>
                        <ImageContainer>
                            <img src={disrupt2016} alt={'Winner of disrupt 2016'} />
                        </ImageContainer>
                        <ImageContainer>
                            <img src={disrupt2015} alt={'Winner of disrupt 2015'} />
                        </ImageContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SeeMore margintop={'40px'} to={'/about'} />
                    </Col>
                </Row>
            </Container>
            <Container className="d-block d-md-none awards-width">
                <div className="text-center">
                    <AwardsTitle>Awards & Recognitions.</AwardsTitle>
                    <CollageContainer>
                        <img src={collage} alt={''} />
                    </CollageContainer>
                    <LandscapeContainer className="mt-4">
                        <ImageContainer>
                            <img src={goodFirms} alt={'Awarded good firms'} />
                        </ImageContainer>
                        <ImageContainer>
                            <img src={disrupt2016} alt={'Winner of disrupt 2016'} />
                        </ImageContainer>
                        <ImageContainer>
                            <img src={disrupt2015} alt={'Winner of disrupt 2015'} />
                        </ImageContainer>
                    </LandscapeContainer>
                    <SeeMore className="mt-2" to={'/about'} />
                </div>
            </Container>
        </WraperContainer>
    );
};
