import React from 'react';
import styled from 'styled-components';

import { BACKGROUND_COLORS, PRIMARY_TITLE_COLOR, SECONDARY_BG_COLOR, TESTIMONIES, TITLE_FONT } from '../../constants';
import { Col, Row } from 'reactstrap';
import { Testimony } from '../testimony/testimony';
import { SeeMore } from '../shared';
import star from '../../../static/images/icons/YelowStar.svg';
import { TestimoniesCarousel } from '../testimonies-carousel/testimonies-carousel';

const Container = styled.div`
    max-width: 1400px;
    align-self: center;
`;

const Customers = styled.div`
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px 138px 50px;
    max-width: 100vw;

    .container {
        .what-customers-say {
            margin-bottom: 3rem;
            @media (max-height: 568px) {
                margin-bottom: 0;
                margin-top: 4rem;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 50px 16px 20px 16px;
    }
`;

const CustomersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    gap: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        margin: 0 auto 40px auto;
    }
`;

const CustomersTextContainer = styled.div``;

const CustomersTitle = styled.h2`
    font-family: ${TITLE_FONT};
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 9px;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const ScoreText = styled.p`
    font-family: ${TITLE_FONT};
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: 34.478px;
    margin: 0;
`;

const Score = styled.p`
    font-family: ${TITLE_FONT};
    color: ${SECONDARY_BG_COLOR};
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 120px */
    letter-spacing: -0.4px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Star = styled.img`
    height: 25px;
`;

const StarContainer = styled.div`
    display: flex;
    gap: 5px;
`;

const WhatCustomers = styled.a`
    font-size: 32px;
    letter-spacing: -0.4px;
    font-family: ${TITLE_FONT};
    font-weight: bold;
    color: ${PRIMARY_TITLE_COLOR};
    margin-bottom: 80px;
    margin-top: 80px;

    @media (max-height: 700px) {
        margin-bottom: 50px;
        font-size: 32px;

        br {
            display: none;
        }
    }

    @media (max-width: 768px) {
        font-size: 28px;
        letter-spacing: -0.2px;
        padding-top: 40px;
        margin-bottom: 40px;

        br {
            display: block;
        }
    }
`;

const HeightConservingDiv = styled.div`
    display: none;
    margin-top: 20px;
    @media screen and (min-height: 679px) {
        display: block;
    }
`;

export const Testimonies = () => {
    return (
        <Customers key="testimonies">
            <Container style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Row className={'what-customers-say m-0'} style={{ width: '100%' }}>
                    <CustomersContainer>
                        <CustomersTextContainer>
                            <CustomersTitle>Customers love us on Clutch</CustomersTitle>
                            <ScoreText>More than 20 reviews with an average score of </ScoreText>
                        </CustomersTextContainer>
                        <Score>
                            5.0
                            <StarContainer>
                                <Star src={star} alt={'Star icon'} />
                                <Star src={star} alt={'Star icon'} />
                                <Star src={star} alt={'Star icon'} />
                                <Star src={star} alt={'Star icon'} />
                                <Star src={star} alt={'Star icon'} />
                            </StarContainer>
                        </Score>
                    </CustomersContainer>
                </Row>
                <Row className={'mb-5'} style={{ width: '100%', margin: 0 }}>
                    {TESTIMONIES.map((item, index) => {
                        return (
                            <Col lg={4} xs={12} className={`m-0 ${index >= 1 ? 'd-none d-lg-block' : ''}`} key={'testimony-' + index}>
                                <Testimony
                                    position={item.title}
                                    name={item.name}
                                    source={item.source}
                                    image={item.image}
                                    quote={item.text}
                                    sourceHref={item.sourceHref}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-5 mt-md-0" style={{ alignSelf: 'flex-start' }}>
                    <Col>
                        <HeightConservingDiv>
                            <SeeMore to="https://clutch.co/profile/equaleyes-solutions" />
                        </HeightConservingDiv>
                    </Col>
                </Row>
            </Container>
        </Customers>
    );
};
