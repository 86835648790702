import React from 'react';
import styled from 'styled-components';

import { CallToActionButton } from '../shared';
import { Link } from 'gatsby';
import { Col, Row } from 'reactstrap';
import { PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';
import Animation from '/public/images/FrontAnimation.svg';
import { TestimoniesCarousel } from '../testimonies-carousel/testimonies-carousel';

const WeCreate = styled.h1`
    font-family: ${TITLE_FONT};
    letter-spacing: -0.4px;
    margin-bottom: 92px;
    color: ${PRIMARY_TEXT_COLOR};
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 100px;
    padding-right: 15px;
    display: ${params => params.isMobile ? 'none' : 'block'};

    @media (max-width: 768px) {
        letter-spacing: -0.2px;
        font-size: 32px;
        margin-bottom: 56px;
        display: ${params => params.isMobile ? 'block' : 'none'};
        margin-top: 40px;
    }
`;

// const ScrollDownContainer = styled.div`
//     margin-top: 30px;
//     border: 3px solid rgba(255, 255, 255, 0.7);
//     border-radius: 50px;
//     height: 30px;
//     width: 20px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     left: 50%;
//     opacity: 0.6;
//     transform: translateX(-50%);
//     animation: fade 4s infinite cubic-bezier(0.75, -0.5, 0, 1.75);
//
//     @keyframes fade {
//         0%,
//         100% {
//             opacity: 1;
//             transform: scale(1);
//         }
//         85% {
//             opacity: 0.3;
//             transform: scale(0.9);
//         }
//     }
//     .wheel {
//         width: 3px;
//         height: 3px;
//         background: #fff;
//         display: block;
//         border-radius: 100%;
//         margin-top: 5px;
//         animation: boop 4s infinite cubic-bezier(0.75, -0.5, 0, 1.75);
//     }
//     @keyframes boop {
//         0%,
//         100% {
//             transform: translateY(0) scale(1);
//         }
//         85% {
//             transform: translateY(10px) scale(0.5);
//             opacity: 0.3;
//         }
//     }
// `;
//
// const ScrollDown = () => {
//     return (
//         <ScrollDownContainer>
//             <div className={'wheel'} />
//         </ScrollDownContainer>
//     );
// };

const Container = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url('/images/BackgroundFront.jpg'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
`;

const BlurOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(100px);
    z-index: 2;
`;

const SVGAnimation = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const TextOverlay = styled.div`
    position: absolute;
    max-width: 1140px;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    padding-left: 15px;

    @media (max-width: 1200px) {
        max-width: 960px;
    }

    @media (max-width: 991px) {
        max-width: 720px;
    }

    @media (max-width: 768px) {
        max-width: 540px;
        padding-left: 5%;
    }

    @media (max-width: 575px) {
        max-width: unset;
        padding-left: 16px;
    }
`;

const AnimationImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TestimoniesCarouselWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: 40px;
    z-index: 2;

    @media (max-width: 768px) {
        margin-bottom: 64px;
    }
`;

export const FrontPage = () => {
    return (
        <Container>
            <BlurOverlay />
            <SVGAnimation>
                <AnimationImg src={Animation} alt="Animated SVG" />
            </SVGAnimation>
            <TextOverlay>
                <Row>
                    <Col>
                        <WeCreate isMobile={false}>
                            Building the future of software
                            <br /> with cutting‑edge solutions.
                        </WeCreate>
                        <WeCreate isMobile={true}>
                            Building the future of software with cutting‑edge solutions.
                        </WeCreate>
                    </Col>
                    <Col xs={12}>
                        <Link to={'/contact'}>
                            <CallToActionButton mb={'0'} biggerButton={true}>
                                <span>Let's start</span>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.8125 0.21875C6.9375 0.0625 7.1875 0.0625 7.34375 0.21875L13.875 6.75C14.0312 6.90625 14.0312 7.125 13.875 7.28125L7.34375 13.8125C7.1875 13.9688 6.9375 13.9688 6.8125 13.8125L6.1875 13.2188C6.03125 13.0625 6.03125 12.8125 6.1875 12.6875L11.0312 7.8125H0.375C0.15625 7.8125 0 7.65625 0 7.4375V6.5625C0 6.375 0.15625 6.1875 0.375 6.1875H11.0312L6.1875 1.34375C6.03125 1.21875 6.03125 0.96875 6.1875 0.8125L6.8125 0.21875Z" fill="#F0F7FC" />
                                </svg>
                            </CallToActionButton>
                        </Link>
                    </Col>
                </Row>
            </TextOverlay>
            <TestimoniesCarouselWrapper>
                <TestimoniesCarousel />
            </TestimoniesCarouselWrapper>
        </Container>
    );
};
