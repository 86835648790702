import { useState, useEffect, useCallback } from 'react';
import { useScrollingAverage } from './scrolling-average.hook';

const EVENTS = {
    WHEEL: 'wheel',
    KEY_UP: 'keyup',
    TOUCH_START: 'touchstart',
    TOUCH_MOVE: 'touchmove',
    TOUCH_END: 'touchend',
};

export function useScrollingPage(pages, options) {
    const [page, setPage] = useState(0);
    const [canScroll, setCanScroll] = useState(true);

    const safelySetPage = useCallback(
        direction => {
            if (page + direction >= 0 && page + direction < pages) {
                setPage(page + direction);
            }
        },
        [page, pages],
    );

    const wheelHandler = useScrollingAverage(safelySetPage, options);
    const keyHandler = useKeyHandler(safelySetPage);
    const [touchStartHandler, touchMoveHandler, touchEndHandler] = useSwipeHandlers(safelySetPage);

    useEffect(() => {
       const wheelHandlerCopy = wheelHandler.current; 
        if (canScroll) {
            window.addEventListener(EVENTS.WHEEL, wheelHandlerCopy);
            window.addEventListener(EVENTS.KEY_UP, keyHandler);
            window.addEventListener(EVENTS.TOUCH_START, touchStartHandler);
            window.addEventListener(EVENTS.TOUCH_MOVE, touchMoveHandler);
            window.addEventListener(EVENTS.TOUCH_END, touchEndHandler);
        }

        return () => {
            window.removeEventListener(EVENTS.WHEEL, wheelHandlerCopy);
            window.removeEventListener(EVENTS.KEY_UP, keyHandler);
            window.removeEventListener(EVENTS.TOUCH_START, touchStartHandler);
            window.removeEventListener(EVENTS.TOUCH_MOVE, touchMoveHandler);
            window.removeEventListener(EVENTS.TOUCH_END, touchEndHandler);
        };
    }, [canScroll, wheelHandler, keyHandler, touchStartHandler, touchMoveHandler, touchEndHandler]);

    return { page, setPage, canScroll, setCanScroll };
}

function useSwipeHandlers(callback) {
    let [currentY, setCurrentY] = useState(null);

    const touchStart = useCallback(
        e => {
            setCurrentY(e.changedTouches[0].pageY);
        },
        [],
    );
    const touchMove = useCallback(e => {
        // e.preventDefault();
    }, []);
    const touchEnd = useCallback(
        e => {
            if (currentY - e.changedTouches[0].pageY > 50) {
                callback(1);
            } else if (currentY - e.changedTouches[0].pageY < -50) {
                callback(-1);
            }
        },
        [callback, currentY],
    );

    return [touchStart, touchMove, touchEnd];
}

function useKeyHandler(callback) {
    return useCallback(
        evt => {
            switch (evt.keyCode) {
                case 38:
                    return callback(-1);
                case 40:
                    return callback(1);
                default:
                    return;
            }
        },
        [callback],
    );
}
