import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import React from 'react';
import { PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';
import { Footer } from '../footer';

const FooterContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    @media (max-width: 667px) {
        height: auto;
    }
`;

const Text = styled.p`
    font-family: ${TITLE_FONT};
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: 0.4px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-height: 700px) {
        font-size: 18px;
    }
`;

const SubText = styled(Text)`
    margin-top: 100px;

    @media (max-height: 700px) {
        margin-top: 50px;
    }

    font-weight: 400;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: 160px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    @media (max-width: 992px) {
        height: 100%;
    }
`;

export const LastPage = (props) => {
    return (
        <Wrapper>
            <FooterContainer className="d-none d-lg-flex">
                <DescriptionContainer>
                    <Row>
                        <Col md={3}>
                            <Text>We are a family that has grown closer and more loyal every year we have been together.</Text>
                        </Col>
                        <Col md={1} />
                        <Col md={3}>
                            <Text>We began the journey believing in ourselves and bravely taking on challenges.</Text>
                        </Col>
                        <Col md={1} />
                        <Col md={3}>
                            <Text>We won numerous awards and established our reputation for brilliance.</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SubText>We are Equaleyes.</SubText>
                        </Col>
                    </Row>
                </DescriptionContainer>
            </FooterContainer>
            <Footer hideLogo={props.hideLogo} />
        </Wrapper>
    );
};
