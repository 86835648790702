import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { META } from '../constants';

export const Seo = React.memo(props => {
    const { data } = props;
    const postTitle = ((data || {}).frontmatter || {}).title;
    const postDescription = ((data || {}).frontmatter || {}).description;
    const postCover = ((data || {}).frontmatter || {}).cover;
    const postSlug = ((data || {}).fields || {}).slug;

    const title = postTitle ? `${postTitle} - ${META.shortSiteTitle}` : META.siteTitle;
    const description = postDescription ? postDescription : META.siteDescription;
    const image = postCover ? postCover : META.siteImage;
    const url = META.siteUrl + META.pathPrefix + postSlug;

    return (
        <Helmet
            htmlAttributes={{
                lang: META.siteLanguage,
                prefix: 'og: http://ogp.me/ns#',
            }}
        >
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content={META.appId ? META.appID : ''} />
            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={META.authorTwitterAccount ? META.authorTwitterAccount : ''} />
        </Helmet>
    );
});

Seo.propTypes = {
    data: PropTypes.object,
};
