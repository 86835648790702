import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BACKGROUND_COLORS, FEATURE_COLORS, SECONDARY_BG_COLOR, TEXT_FONT, TITLE_FONT } from '../../constants';
import line1_1 from '../../../static/images/automatic-carousel/line1_1.jpg';
import line1_2 from '../../../static/images/automatic-carousel/line1_2.jpg';
import line1_3 from '../../../static/images/automatic-carousel/line1_3.jpg';
import line1_4 from '../../../static/images/automatic-carousel/line1_4.jpg';
import line1_5 from '../../../static/images/automatic-carousel/line1_5.jpg';
import line1_6 from '../../../static/images/automatic-carousel/line1_6.jpg';
import line1_7 from '../../../static/images/automatic-carousel/line1_7.jpg';
import line1_8 from '../../../static/images/automatic-carousel/line1_8.jpg';
import line1_9 from '../../../static/images/automatic-carousel/line1_9.jpg';
import line1_10 from '../../../static/images/automatic-carousel/line1_10.jpg';
import line1_11 from '../../../static/images/automatic-carousel/line1_11.jpg';
import line1_12 from '../../../static/images/automatic-carousel/line1_12.jpg';
import line1_13 from '../../../static/images/automatic-carousel/line1_13.jpg';
import line1_14 from '../../../static/images/automatic-carousel/line1_14.jpg';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${BACKGROUND_COLORS.color_bg_light};
`;

const scrollAnimation = keyframes`
    0% {transform: translateX(0);}
    100% {transform: translateX(calc(-480px*14 - 14*24px ))}
`;

const Carousel = styled.div`
    margin-top: 40px;
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    overflow: hidden;
    position: relative;
    max-width: 1800px;

    @media (min-width: 1800px) {
        &::before,
        &::after {
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            content: '';
            height: 240px;
            position: absolute;
            width: 200px;
            z-index: 2;
        }

        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            left: 0;
            top: 0;
        }
    }
`;

const Track = styled.div`
    animation: ${scrollAnimation} 60s linear infinite;
    display: flex;
    align-items: center;
    height: 350px;
    gap: 24px;
    width: calc(14 * 480px);
`;

const Item = styled.div`
    height: 240px;
    width: 480px;
`;

const Image = styled.img`
    height: 240px;
    width: 480px;
    box-shadow: 0 4px 40px 0 ${FEATURE_COLORS.shadow2};
    border-radius: 24px;
    margin-bottom: 32px;
`;

const Title = styled.h1`
    margin-bottom: 32px;
    text-align: center;
    font-family: ${TITLE_FONT};
    color: ${SECONDARY_BG_COLOR};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 32px;
        margin: 0 16px 40px 16px;
    }
`;

const Subtitle = styled.h2`
    margin: 0 16px;
    color: ${SECONDARY_BG_COLOR};
    text-align: center;
    font-family: ${TEXT_FONT};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.478px;
    max-width: 836px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const Line1 = [
    line1_1,
    line1_2,
    line1_3,
    line1_4,
    line1_5,
    line1_6,
    line1_7,
    line1_8,
    line1_9,
    line1_10,
    line1_11,
    line1_12,
    line1_13,
    line1_14,
    line1_1,
    line1_2,
    line1_3,
    line1_4,
    line1_5,
    line1_6,
    line1_7,
    line1_8,
    line1_9,
    line1_10,
    line1_11,
    line1_12,
    line1_13,
    line1_14,
];

export const AwardsCarousel = () => {
    return (
        <Wrapper>
            <Title>Top-Rated Excellence</Title>
            <Subtitle>
                Our applications have won numerous awards, both in mobile and web development markets, thanks to their adoption of
                outstanding innovations and quality which are appreciated everywhere.
            </Subtitle>
            <Carousel>
                <Track>
                    {Line1.map(image => (
                        <Item>
                            <Image src={image} />
                        </Item>
                    ))}
                </Track>
            </Carousel>
        </Wrapper>
    );
};
