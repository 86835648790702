import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    BACKGROUND_COLORS,
    CLUTCH_STATS,
    FEATURE_COLORS,
    PRIMARY_TITLE_COLOR,
    SECONDARY_TITLE_COLOR,
    TEXT_FONT,
    TITLE_FONT,
} from '../../constants';
import star from '../../../static/images/icons/YelowStar.svg';

const Container = styled.div`
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    box-shadow: 0 8px 36px 1px ${FEATURE_COLORS.shadow3};
    border-radius: 16px;
    padding: 40px 32px;
`;

const Quote = styled.div`
    flex-grow: 1;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_TITLE_COLOR};
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: 34.478px;

    @media (max-width: 768px) {
        font-size: 18px;
        letter-spacing: -0.1px;
    }

    @media (max-height: 700px) {
        font-size: 18px;
        letter-spacing: -0.1px;
    }
`;

const Meta = styled.div`
    flex-grow: 0;
    margin-bottom: 0px;
`;

const Title = styled.div`
    margin-top: 30px;
    font-weight: bold;
    line-height: 22px;
    font-family: ${TEXT_FONT};
    color: ${SECONDARY_TITLE_COLOR};
    letter-spacing: -0.2px;
    font-size: 18px;
    margin-bottom: 4px;

    @media (max-height: 700px) {
        font-size: 15px;
        letter-spacing: -0.1px;
    }
`;

const Name = styled.div`
    margin-bottom: 30px;
    font-family: ${TEXT_FONT};
    line-height: 22px;
    font-family: ${TEXT_FONT};
    letter-spacing: -0.2px;
    font-size: 18px;

    @media (max-height: 700px) {
        font-size: 15px;
        letter-spacing: -0.1px;
    }
`;

const StarsContainer = styled.div``;

const ImageContainer = styled.div`
    height: 45px;
    img {
        height: 40px;
    }
`;

const Image = styled.img``;

const Source = styled.a`
    font-family: ${TEXT_FONT};
    color: ${SECONDARY_TITLE_COLOR};
    letter-spacing: -0.2px;
    font-size: 18px;
`;

export const Testimony = ({ quote, position, name, image, imageAlt, source, sourceHref }) => {
    return (
        <Container>
            <ImageContainer>
                <Image src={image} alt={imageAlt} />
            </ImageContainer>
            <StarsContainer>
                <img src={star} alt={'Star icon'} />
                <img src={star} alt={'Star icon'} />
                <img src={star} alt={'Star icon'} />
                <img src={star} alt={'Star icon'} />
                <img src={star} alt={'Star icon'} />
            </StarsContainer>
            <Quote>{quote}</Quote>
            <Meta>
                <Title>{position}</Title>
                <Name>{name}</Name>
            </Meta>
            {source !== null && <Source href={sourceHref}>{source}</Source>}
        </Container>
    );
};

Testimony.propTypes = {
    quote: PropTypes.string,
    position: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    source: PropTypes.string,
    sourceHref: PropTypes.string,
};

Testimony.defaultProps = {
    source: 'Source: Clutch.co',
};
